import React from 'react';
import BreadcrumbSchema from '../../components/BreadcrumbSchema';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { FaSearchPlus } from 'react-icons/fa';
import ServiceTemplate from '../../components/service/ServiceTemplate';

const AuditService = ({ data }) => {
  const seoData = {
    title: 'サイト調査（サイト診断）｜奈良のホームページ制作なら｜インヴォルブ',
    description:
      '今のWebサイトにどんな問題があるのか？デザイン・SEO・パフォーマンス・ユーザビリティを分析し、改善提案を提供します。',
    pageUrl: 'https://www.involve-in.jp/service/audit',
    ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
  };

  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    { name: 'サービス一覧', url: 'https://www.involve-in.jp/service/' },
    { name: 'サイト調査（サイト診断）', url: seoData.pageUrl },
  ];

  const works = data.allContentfulCaseStudy.nodes;

  return (
    <>
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <ServiceTemplate
        title="サイト調査（サイト診断）"
        subtitle="サイトの課題を明確化"
        icon={FaSearchPlus}
        description="今のWebサイトにどんな問題があるのか？デザイン・SEO・パフォーマンス・ユーザビリティを分析し、改善提案を提供します。"
        seo={seoData}
        breadcrumbs={breadcrumbList}
        benefits={[
          {
            title: 'サイトの課題を可視化',
            description: '問題点を明確にし、最適な改善策を提案します。',
          },
          {
            title: '競合分析も対応',
            description: '業界内でのポジションを把握し、差別化戦略を提案。',
          },
          {
            title: '改善の優先度を明示',
            description: 'すぐに対応すべき項目を明確化し、効果的な対策を提案。',
          },
        ]}
        flow={[
          {
            title: '現状ヒアリング',
            duration: '1日',
            description:
              'サイトの目的や運用状況をヒアリングし、診断の方向性を決定。',
          },
          {
            title: 'サイト診断',
            duration: '3日〜',
            description:
              'デザイン・SEO・パフォーマンス・ユーザビリティを総合分析。',
          },
          {
            title: 'レポート作成',
            duration: '1週間',
            description: '診断結果をレポートにまとめ、問題点と改善策を提示。',
          },
          {
            title: '改善提案',
            duration: null,
            description: '優先度の高い対策を整理し、具体的な改善施策を提案。',
          },
        ]}
        preparation={[
          {
            title: '診断の目的を明確化',
            description: 'SEO改善・デザイン改修など、診断の目的を決める。',
          },
          {
            title: 'アクセスデータの提供',
            description:
              'Google Analyticsなどのアクセスデータがあると診断が精密に。',
          },
          {
            title: '競合サイトの情報',
            description: '気になる競合サイトがあれば、診断の参考に。',
          },
          {
            title: '現在の問題点の整理',
            description: 'サイト運用で困っていることをリストアップ。',
          },
        ]}
        works={works.map((work) => ({
          title: work.title,
          image:
            work.image && work.image.length > 0
              ? getImage(work.image[0].gatsbyImageData)
              : null,
        }))}
        price="¥30,000〜"
        cta="診断を依頼する"
      />
    </>
  );
};

export const query = graphql`
  query {
    allContentfulCaseStudy(sort: { publishDate: DESC }) {
      nodes {
        title
        slug
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            formats: [AUTO, WEBP]
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;

export default AuditService;
